export const featuredData = [
  { name: "Packaging of the World" },
  { name: "World Brand Design Society" },
  { name: "Brand Awesome" },
  { name: "Canva" },
  { name: "Lux Magazine" },
  { name: "Monotype" },
  { name: "Myfonts" },
  { name: "Clutch" },
  { name: "Adobo Magazine" },
  { name: "GMA" },
  { name: "APAC Insider" },
  { name: "Sunstar" }
]
